import { PLATFORM_URL_LOGIN } from '@app/lib';

export const typeformDk = 'https://wgihj9nhghy.typeform.com/to/ARH3BW5v';
export const typeformSe = 'https://wgihj9nhghy.typeform.com/to/aRWpdKNr';
const domainsWithSupportedLanguage = ['no'];

// returns the country code based on the end of the domain
const getCountryCode = (): string => {
  const domain = window.location.hostname;
  const countryCode = domain.split('.').pop() || '';
  return countryCode;
};

export const start_trial_link = () => {
  const countryCode = getCountryCode();
  if (countryCode === 'dk') {
    return typeformDk;
  } else if (countryCode === 'se') {
    return typeformSe;
  }
  return PLATFORM_URL_LOGIN;
};

export const isSupportedLanguage = (): boolean => {
  const domain = getCountryCode();
  return domainsWithSupportedLanguage.includes(domain);
};
