import React, { FC } from 'react';
import styles from './Slogan.module.css';
import { FaArrowUp } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { start_trial_link } from '@app/util/language';
import { trackStartTrialClick } from '@app/util';

const Slogan: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.sloganContainer}>
      <div className={styles.sloganInnerContainer}>
        <div className={styles.sloganContent}>
          <h2>{t('pages.home.slogan')}</h2>
        </div>
        <div className={styles.sloganButtons}>
          <a
            href={start_trial_link()}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            onClick={trackStartTrialClick}
          >
            <div className={styles.sloganArrowButton}>
              <FaArrowUp className={styles.sloganArrowButtonIcon} />
            </div>
          </a>
          <a
            href={start_trial_link()}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            onClick={trackStartTrialClick}
          >
            <div className={styles.sloganContactInfoButton}>
              <p>{t('buttons.start_trial')}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Slogan;
