import styles from './UseCasesSection.module.css';
import doctorImage from '@app/images/useCasesSectionImg.svg';
import primaryCareIcon from '@app/images/icons/primary_care.svg';
import specialistCareIcon from '@app/images/icons/specialist_care.svg';
import psychiatryIcon from '@app/images/icons/psychiatry.svg';
import nursingIcon from '@app/images/icons/nursing.svg';
import physiotherapyIcon from '@app/images/icons/physiotherapy.svg';
import chiropracticIcon from '@app/images/icons/chiropractic.svg';
import veterinaryIcon from '@app/images/icons/veterinary.svg';
import readMoreImg from '@app/images/useCasesReadMore.svg';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '@app/lib/i18n';

const useCases = {
  primary_care: {
    id: 1,
    title: i18n.t('sections.use_cases.primary_care.title'),
    icon: primaryCareIcon,
    text: i18n.t('sections.use_cases.primary_care.text'),
  },
  specialist_care: {
    id: 2,
    title: i18n.t('sections.use_cases.specialist_care.title'),
    icon: specialistCareIcon,
    text: i18n.t('sections.use_cases.specialist_care.text'),
  },
  psychiatry: {
    id: 3,
    title: i18n.t('sections.use_cases.psychiatry.title'),
    icon: psychiatryIcon,
    text: i18n.t('sections.use_cases.psychiatry.text'),
  },
  nursing: {
    id: 4,
    title: i18n.t('sections.use_cases.nursing.title'),
    icon: nursingIcon,
    text: i18n.t('sections.use_cases.nursing.text'),
  },
  physiotherapy: {
    id: 5,
    title: i18n.t('sections.use_cases.physiotherapy.title'),
    icon: physiotherapyIcon,
    text: i18n.t('sections.use_cases.physiotherapy.text'),
  },
  chiropractic: {
    id: 6,
    title: i18n.t('sections.use_cases.chiropractic.title'),
    icon: chiropracticIcon,
    text: i18n.t('sections.use_cases.chiropractic.text'),
  },
  veterinary: {
    id: 7,
    title: i18n.t('sections.use_cases.veterinary.title'),
    icon: veterinaryIcon,
    text: i18n.t('sections.use_cases.veterinary.text'),
  },
};

const UseCasesSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.useCasesSectionContainer}>
      <div className={styles.useCasesSectionInnerContainer}>
        <div className={styles.useCasesSectionTopSection}>
          <img src={doctorImage} alt={t('common.healthcare_personnel')} />
          <div className={styles.useCasesSectionTopSectionTextContainer}>
            <p className={styles.useCasesSectionTopSectionHeader}>
              {t('pages.home.use_cases.title')}
            </p>
            <p className={styles.useCasesSectionTopSectionText}>
              {t('pages.home.use_cases.subtitle')}
            </p>
          </div>
        </div>

        <div className={styles.useCasesSectionUseCasesContainer}>
          <div className={styles.useCasesSectionUseCasesRows}>
            {Object.values(useCases)
              .slice(0, 4)
              .map((useCase, index) => (
                <div key={index} className={styles.useCasesSectionUseCase}>
                  <div className={styles.useCasesSectionUseCaseHeaderContainer}>
                    <img src={useCase.icon} alt={useCase.title} />
                    <p>{useCase.title}</p>
                  </div>
                  <div className={styles.useCasesSectionUseCaseTextContainer}>
                    <p>{useCase.text}</p>
                  </div>
                </div>
              ))}
            {Object.values(useCases)
              .slice(4)
              .map((useCase, index) => (
                <div key={index} className={styles.useCasesSectionUseCase}>
                  <div className={styles.useCasesSectionUseCaseHeaderContainer}>
                    <img src={useCase.icon} alt={useCase.title} />
                    <p>{useCase.title}</p>
                  </div>
                  <div className={styles.useCasesSectionUseCaseTextContainer}>
                    <p>{useCase.text}</p>
                  </div>
                </div>
              ))}
            <div className={styles.useCasesSectionReadMore}>
              <img src={readMoreImg} alt={t('buttons.read_more')} />
              <div
                className={styles.useCasesSectionReadMoreTextContainer}
                onClick={() => navigate('/bruksomraader')}
              >
                <p>{t('buttons.read_more')}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                >
                  <path
                    d="M0.5 5.06897H9.51985L6.01654 1.56566L7.11736 0.464844L12.5 5.84748L7.11736 11.2301L6.01654 10.1293L9.51985 6.626H0.5V5.06897Z"
                    fill="#403B36"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCasesSection;
