import React, { FC } from 'react';
import styles from './TeamPage.module.css';
import { useTranslation } from 'react-i18next';
import { teamMembers, advisoryBoard } from './content';
import { Helmet } from 'react-helmet-async';

const TeamPage: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Team | Noteless</title>
        <meta
          name="description"
          content="Noteless effektiviserer administrativt arbeid for helsepersonell ved hjelp av kunstig intelligens. Prøv Noteless gratis i 3 uker og opplev en enklere hverdag."
        />
        <link rel="canonical" href="https://www.noteless.no/" />
      </Helmet>
      <div className={styles.teamPageContainer}>
        <div className={styles.teamPageInnerContainer}>
          <div className={styles.teamPageContentContainer}>
            <div className={styles.teamPageHeader}>
              <h2 className={styles.teamPageHeaderTitle}>
                {t('pages.team.title')}
              </h2>
              <p className={styles.teamPageHeaderText}>
                {t('pages.team.text')}
              </p>
            </div>
            <div className={styles.teamPageMembers}>
              <div className={styles.teamPageMembersHeader}>
                <h3>{t('pages.team.members.title')}</h3>
                <p>{t('pages.team.members.text')}</p>
              </div>
              <div className={styles.teamPageMembersContent}>
                {teamMembers
                  .sort((a, b) => a.priority - b.priority)
                  .map((member, index) => (
                    <div
                      key={index}
                      className={styles.teamPageMembersContentCard}
                    >
                      <img
                        className={styles.teamPageMembersContentCardImg}
                        src={`/members/${member.image}`}
                        alt={`${member.name}`}
                      />
                      <div
                        className={
                          styles.teamPageMembersContentCardNameContainer
                        }
                      >
                        <p>{member.name}</p>
                      </div>
                      <div
                        className={styles.teamPageMembersContentCardPosition}
                      >
                        <p>{member.position}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.teamPageAdvisoryBoard}>
              <div className={styles.teamPageAdvisoryBoardHeader}>
                <h3 className={styles.teamPageAdvisoryBoardHeaderTitle}>
                  {t('pages.team.advisory_board')}
                </h3>
              </div>
              <div className={styles.teamPageAdvisoryBoardContent}>
                {advisoryBoard
                  .sort((a, b) => a.priority - b.priority)
                  .map((member, index) => (
                    <div
                      key={index}
                      className={styles.teamPageMembersContentCard}
                    >
                      <img
                        className={styles.teamPageMembersContentCardImg}
                        src={`/members/${member.image}`}
                        alt={`${member.name}`}
                      />
                      <div
                        className={
                          styles.teamPageMembersContentCardNameContainer
                        }
                      >
                        <p>{member.name}</p>
                      </div>
                      <div
                        className={styles.teamPageMembersContentCardPosition}
                      >
                        <p>{member.position}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamPage;
