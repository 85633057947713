import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import nb from './locales/nb.json';
import dk from './locales/dk.json';
import se from './locales/se.json';

// Import the supported languages and add to the resources object
const resources = {
  nb,
  dk,
  se,
};

const getCurrentDomain = () => {
  return window.location.hostname;
};

const getDefaultLanguage = () => {
  const domain = getCurrentDomain();
  if (domain.endsWith('.dk')) {
    return 'dk';
  } else if (domain.endsWith('.no')) {
    return 'nb';
  } else if (domain.endsWith('.se')) {
    return 'se';
  }
  return 'nb';
};

void i18n
  .use(LanguageDetector) // Possibility to detect user language
  .use(initReactI18next)
  .init({
    resources, // Language resources
    fallbackLng: getDefaultLanguage(), // Default language
    debug: true,
    returnObjects: true, // Able to return objects and arrays instead of strings
  });

export default i18n;
