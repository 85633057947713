import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Hero,
  Quotes,
  SupportingPartners,
  Personvern,
  Slogan,
  HowItWorks,
  UseCasesSection,
  VideoSection,
  GetStarted,
} from '@app/components';
import { useWindowWidth } from '@app/hooks';

const MainPage: FC = () => {
  const windowWidth = useWindowWidth();

  return (
    <>
      <Helmet>
        <title>Noteless</title>
        <meta
          name="description"
          content="Noteless effektiviserer administrativt arbeid for helsepersonell ved hjelp av kunstig intelligens. Prøv Noteless gratis i 3 uker og opplev en enklere hverdag."
        />
        <meta property="og:title" content="Noteless" />
        <meta
          property="og:description"
          content="Noteless fungerer som en digital assistent, og skriver notatene for deg. Prøv gratis i 3 uker."
        />
        <link rel="canonical" href="https://www.noteless.no/" />
      </Helmet>
      <Hero windowWidth={windowWidth} />
      <VideoSection />
      <Quotes windowWidth={windowWidth} />
      <GetStarted />
      <HowItWorks />
      <SupportingPartners />
      <UseCasesSection />
      <Personvern />
      <Slogan />
    </>
  );
};

export default MainPage;
