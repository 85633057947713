import React, { useEffect } from 'react';
import styles from './UseCases.module.css';
import primaryCareIcon from '@app/images/icons/primary_care.svg';
import specialistCareIcon from '@app/images/icons/specialist_care.svg';
import psychiatryIcon from '@app/images/icons/psychiatry.svg';
import nursingIcon from '@app/images/icons/nursing.svg';
import physiotherapyIcon from '@app/images/icons/physiotherapy.svg';
import chiropracticIcon from '@app/images/icons/chiropractic.svg';
import veterinaryIcon from '@app/images/icons/veterinary.svg';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';
import i18n from '@app/lib/i18n';

const useCasesDoctors = {
  primary_care: {
    title: i18n.t('sections.use_cases.primary_care.title'),
    icon: primaryCareIcon,
    text: i18n.t('sections.use_cases.primary_care.text'),
  },
  specialist_care: {
    title: i18n.t('sections.use_cases.specialist_care.title'),
    icon: specialistCareIcon,
    text: i18n.t('sections.use_cases.specialist_care.text'),
  },
  psychiatry: {
    title: i18n.t('sections.use_cases.psychiatry.title'),
    icon: psychiatryIcon,
    text: i18n.t('sections.use_cases.psychiatry.text'),
  },
  nursing: {
    title: i18n.t('sections.use_cases.nursing.title'),
    icon: nursingIcon,
    text: i18n.t('sections.use_cases.nursing.text'),
  },
  physiotherapy: {
    title: i18n.t('sections.use_cases.physiotherapy.title'),
    icon: physiotherapyIcon,
    text: i18n.t('sections.use_cases.physiotherapy.text'),
  },
  chiropractic: {
    title: i18n.t('sections.use_cases.chiropractic.title'),
    icon: chiropracticIcon,
    text: i18n.t('sections.use_cases.chiropractic.text'),
  },
  veterinary: {
    title: i18n.t('sections.use_cases.veterinary.title'),
    icon: veterinaryIcon,
    text: i18n.t('sections.use_cases.veterinary.text'),
  },
};

const UseCases = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Bruksområder | Noteless</title>
        <meta
          name="description"
          content="Noteless effektiviserer administrativt arbeid for helsepersonell ved hjelp av kunstig intelligens. Prøv Noteless gratis i 3 uker og opplev en enklere hverdag."
        />
        <link rel="canonical" href="https://www.noteless.no/" />
      </Helmet>
      <div className={styles.useCasesContainer}>
        <div className={styles.useCasesInnerContainer}>
          <div className={styles.useCasesContentContainer}>
            <div className={styles.useCasesContentInnerContainer}>
              <h1 className={styles.useCasesHeader}>
                {t('pages.use_cases.title')}
              </h1>
              <p className={styles.useCasesIngress}>
                {t('pages.use_cases.ingress')}
              </p>
              <div className={styles.useCasesSections}>
                <div className={styles.useCasesSection}>
                  <div className={styles.useCasesSectionCardContainer}>
                    {Object.values(useCasesDoctors).map((useCase, index) => (
                      <div key={index} className={styles.useCasesSectionCard}>
                        <div
                          className={styles.useCasesSectionCardHeaderContainer}
                        >
                          <img src={useCase.icon} alt={useCase.title} />
                          <p className={styles.useCasesSectionCardHeader}>
                            {useCase.title}
                          </p>
                        </div>
                        <div
                          className={styles.useCasesSectionCardTextContainer}
                        >
                          <p className={styles.useCasesSectionCardText}>
                            {useCase.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseCases;
