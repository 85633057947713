import React, { useState, useEffect } from 'react';
import styles from './PersonvernPage.module.css';
import dla_piper from '@app/images/partners/dla_piper.png';
import nøkkelIllustrasjon from '@app/images/nøkkel_illustrasjon.svg';
import { useTranslation } from 'react-i18next';
import i18n from '@app/lib/i18n';
import { Helmet } from 'react-helmet-async';

const policies = {
  first: {
    subheader: i18n.t('pages.privacy.protection.policies.0.subheader'),
    text: (
      <p className={styles.personvernPageInfoContentText}>
        {i18n.t('pages.privacy.protection.policies.0.text')}
      </p>
    ),
    sub_text: i18n.t('pages.privacy.protection.policies.0.subtext'),
  },
  second: {
    subheader: i18n.t('pages.privacy.protection.policies.1.subheader'),
    text: (
      <p className={styles.personvernPageInfoContentText}>
        {i18n.t('pages.privacy.protection.policies.1.text')}
      </p>
    ),
    sub_text: i18n.t('pages.privacy.protection.policies.1.subtext'),
  },
  third: {
    subheader: i18n.t('pages.privacy.protection.policies.2.subheader'),
    text: (
      <p className={styles.personvernPageInfoContentText}>
        {i18n.t('pages.privacy.protection.policies.2.text')}
        <span className={styles.personvernPageInfoContentTextBold}>
          {i18n.t('pages.privacy.protection.policies.2.span')}
        </span>
      </p>
    ),
    sub_text: i18n.t('pages.privacy.protection.policies.2.subtext'),
  },
  fourth: {
    subheader: i18n.t('pages.privacy.protection.policies.3.subheader'),
    text: (
      <p className={styles.personvernPageInfoContentText}>
        <span style={{ fontWeight: 700 }}>
          {i18n.t('pages.privacy.protection.policies.3.span')}
        </span>
        {i18n.t('pages.privacy.protection.policies.3.text')}
      </p>
    ),
    sub_text: i18n.t('pages.privacy.protection.policies.3.subtext'),
  },
  fifth: {
    subheader: i18n.t('pages.privacy.protection.policies.4.subheader'),
    text: (
      <p className={styles.personvernPageInfoContentText}>
        {i18n.t('pages.privacy.protection.policies.4.text')}
      </p>
    ),
    sub_text: i18n.t('pages.privacy.protection.policies.4.subtext'),
  },
};

const QA = {
  qa1: {
    id: 1,
    question: i18n.t('pages.privacy.faq.qa1.question'),
    answer: i18n.t('pages.privacy.faq.qa1.answer'),
  },
  qa3: {
    id: 3,
    question: i18n.t('pages.privacy.faq.qa3.question'),
    answer: i18n.t('pages.privacy.faq.qa3.answer'),
  },
  qa5: {
    id: 5,
    question: i18n.t('pages.privacy.faq.qa5.question'),
    answer: i18n.t('pages.privacy.faq.qa5.answer'),
  },
  qa6: {
    question: i18n.t('pages.privacy.faq.qa6.question'),
    answer: i18n.t('pages.privacy.faq.qa6.answer'),
  },
};

const PersonvernPage = () => {
  const [showAnswerList, setShowAnswerList] = useState<number[]>([]);
  const [showPolicyList, setShowPolicyList] = useState<number[]>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const { t } = useTranslation();

  useEffect(() => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    }); // Scroll to top on mount
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleShowPolicy = (policyIndex: number) => {
    setShowPolicyList((prevList) => {
      if (prevList.includes(policyIndex)) {
        return prevList.filter((index) => index !== policyIndex);
      } else {
        return [...prevList, policyIndex];
      }
    });
  };
  const handleShowAnswer = (answerIndex: number) => {
    setShowAnswerList((prevList) => {
      if (prevList.includes(answerIndex)) {
        return prevList.filter((index) => index !== answerIndex);
      } else {
        return [...prevList, answerIndex];
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Personvern | Noteless</title>
        <meta
          name="description"
          content="Noteless effektiviserer administrativt arbeid for helsepersonell ved hjelp av kunstig intelligens. Prøv Noteless gratis i 3 uker og opplev en enklere hverdag."
        />
        <link rel="canonical" href="https://www.noteless.no/" />
      </Helmet>
      <div className={styles.personvernPageContainer}>
        <div className={styles.personvernPageInnerContainer}>
          <div className={styles.personvernPageContentContainer}>
            <div className={styles.personvernPageInnerContentContainer}>
              <div className={styles.personvernPageHeaderSection}>
                <h1 className={styles.personvernPageHeader}>
                  {t('pages.privacy.title')}
                </h1>
                <p className={styles.personvernPageIngress}>
                  {t('pages.privacy.ingress')}
                </p>
              </div>
              <div className={styles.personvernPageImageSection}>
                <img
                  src={nøkkelIllustrasjon}
                  alt={t('pages.privacy.key_illustration')}
                />
              </div>
              <div className={styles.personvernPageInfoSection}>
                <h3 className={styles.personvernPageInfoHeader}>
                  {t('pages.privacy.protection.title')}
                </h3>
                <p className={styles.personvernPageInfoIngress}>
                  {t('pages.privacy.protection.ingress')}
                </p>
                {windowWidth < 500 ? (
                  <div className={styles.personvernPageInfoContentMobile}>
                    {Object.values(policies).map((policy, index) => (
                      <div
                        key={index}
                        className={styles.personvernPageInfoContentMobileItem}
                      >
                        <div
                          className={
                            styles.personvernPageInfoContentMobileItemHeaderContainer
                          }
                          onClick={() => handleShowPolicy(index)}
                        >
                          <p
                            className={
                              styles.personvernPageInfoContentMobileItemHeader
                            }
                          >
                            {policy.subheader}
                          </p>
                          {showPolicyList.includes(index) ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                              fill="none"
                            >
                              <path
                                d="M1 7L7 1L13 7"
                                stroke="#4F5D64"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="10"
                              viewBox="0 0 16 10"
                              fill="none"
                            >
                              <path
                                d="M14 2L8 8L2 2"
                                stroke="#403B36"
                                strokeWidth="2"
                                strokeLinecap="square"
                              />
                            </svg>
                          )}
                        </div>
                        {showPolicyList.includes(index) ? (
                          <div
                            className={
                              styles.personvernPageInfoContentMobileItemText
                            }
                          >
                            <p>
                              {policy.text}{' '}
                              {policy.sub_text ? (
                                <>
                                  <br /> <br />
                                  {policy.sub_text}
                                </>
                              ) : null}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.personvernPageInfoContent}>
                    {Object.values(policies).map((policy, index) => (
                      <div key={index}>
                        <p
                          className={styles.personvernPageInfoContentSubHeader}
                        >
                          {policy.subheader}
                        </p>
                        <ul
                          style={{ listStyleType: 'disc', marginLeft: '25px' }}
                        >
                          <li>{policy.text}</li>
                          {policy.sub_text ? (
                            <>
                              <br />
                              <li>{policy.sub_text}</li>
                            </>
                          ) : null}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.personvernPageQASection}>
                <h3 className={styles.personvernPageQAHeader}>
                  {t('pages.privacy.faq.title')}
                </h3>
                <div className={styles.personvernPageQAContent}>
                  {Object.values(QA).map((qa, index) => (
                    <div
                      key={index}
                      className={styles.personvernPageQAQuestionContainer}
                    >
                      <div
                        className={styles.personvernPageQAQuestion}
                        onClick={() => handleShowAnswer(index)}
                      >
                        <p className={styles.personvernPageQAQuestionText}>
                          {qa.question}
                        </p>
                        {showAnswerList.includes(index) ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="none"
                          >
                            <path
                              d="M1 7.73047L7 1.73047L13 7.73047"
                              stroke="#4F5D64"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="none"
                          >
                            <path
                              d="M13 1.73047L7 7.73047L1 1.73047"
                              stroke="#4F5D64"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                      {showAnswerList.includes(index) ? (
                        <div className={styles.personvernPageQAAnswer}>
                          <p>{qa.answer}</p>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.personvernPageDLASection}>
                <div className={styles.personvernPageDLAContainer}>
                  <div className={styles.personvernPageDLAContentContainer}>
                    <h3 className={styles.personvernPageDLAHeader}>
                      {t('sections.privacy_partner.title')}
                    </h3>
                    <p className={styles.personvernPageDLAText}>
                      {t('sections.privacy_partner.content', {
                        company: 'Advokatfirma DLA Piper',
                      })}
                    </p>
                  </div>
                  <img
                    className={styles.personvernPageDLAImage}
                    src={dla_piper}
                    alt="DLA PIPER"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonvernPage;
