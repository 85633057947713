import aleap from '@app/images/partners/aleap.png';
import ntnu_driv from '@app/images/partners/driv.png';
import eitri from '@app/images/partners/eitri.png';
import InnovasjonNorge from '@app/images/partners/innovasjon_norge.svg';
import nscc from '@app/images/partners/nscc.png';
import vis from '@app/images/partners/vis.png';
import webmed from '@app/images/partners/webmed.png';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SupportingPartners.module.css';

const SupportingPartners: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.supportingPartnersContainer}>
      <div className={styles.supportingPartnersInnerContainer}>
        <div className={styles.supportingPartnersTitle}>
          <h2>{t('pages.home.partners.title')}</h2>
        </div>
        <div className={styles.supportingPartnersImagesContainer}>
          <img
            src={InnovasjonNorge}
            alt="Innovasjon Norge"
            className={styles.supportingPartnersIN}
          />
          <img
            src={vis}
            alt="Vis Innovasjon"
            className={styles.supportingPartnersVis}
          />
          <img
            src={aleap}
            alt="Aleap"
            className={styles.supportingPartnersAleap}
          />
          <img
            src={eitri}
            alt="Eitri"
            className={styles.supportingPartnersEitri}
          />
          <img
            src={ntnu_driv}
            alt="NTNU Driv"
            className={styles.supportingPartnersDriv}
          />
          <img
            src={nscc}
            alt="Norwegian Smart Care Cluster"
            className={styles.supportingPartnersNscc}
          />
        </div>
        <div className={styles.supportingPartnerWebMedContainer}>
          <div className={styles.supportingPartnerWebMedInnerContainer}>
            <div className={styles.supportingPartnersWebMedContainerContent}>
              <h3
                className={styles.supportingPartnersWebMedContainerContentTitle}
              >
                {t('pages.home.partners.webmed.title')}
              </h3>
              <p
                className={styles.supportingPartnersWebMedContainerContentText}
              >
                {t('pages.home.partners.webmed.text1')}{' '}
                <a
                  href="https://webmed.no/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WebMed EPJ
                </a>{' '}
                {t('pages.home.partners.webmed.text2')}
              </p>
            </div>
            <img
              className={styles.supportingPartnersWebMed}
              src={webmed}
              alt="WebMed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportingPartners;
