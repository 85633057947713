import primaryCareIcon from '@app/images/icons/primary_care.svg';
import specialistCareIcon from '@app/images/icons/specialist_care.svg';
import nursingIcon from '@app/images/icons/nursing.svg';
import physiotherapyIcon from '@app/images/icons/physiotherapy.svg';
import psychiatryIcon from '@app/images/icons/psychiatry.svg';
import chiropracticIcon from '@app/images/icons/chiropractic.svg';
import veterinaryIcon from '@app/images/icons/veterinary.svg';
import heroCardFirstImg from '@app/images/hero_card_first_img.svg';
import heroCardSecondImg from '@app/images/hero_card_second_img.svg';
import heroCardThirdImg from '@app/images/hero_card_third_img.svg';
import i18n from '@app/lib/i18n';
import { trackStartTrialClick } from '@app/util';
import { start_trial_link } from '@app/util/language';
import { useMeasure } from '@uidotdev/usehooks';
import { motion, useAnimation } from 'framer-motion';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Hero.module.css';

interface HeroInterface {
  windowWidth: number;
}

const CardsSectionContent = {
  first: {
    title: i18n.t('pages.home.hero_cards.first.title'),
    text: i18n.t('pages.home.hero_cards.first.text'),
    img: heroCardSecondImg,
    img_width_desktop: 172.032,
    img_height_desktop: 138.24,
    img_width_mobile: 70,
    img_height_mobile: 48.212,
  },
  second: {
    title: i18n.t('pages.home.hero_cards.second.title'),
    text: i18n.t('pages.home.hero_cards.second.text'),
    img: heroCardFirstImg,
    img_width_desktop: 186.756,
    img_height_desktop: 138,
    img_width_mobile: 70,
    img_height_mobile: 69.247,
  },
  third: {
    title: i18n.t('pages.home.hero_cards.third.title'),
    text: i18n.t('pages.home.hero_cards.third.text'),
    img: heroCardThirdImg,
    img_width_desktop: 241.116,
    img_height_desktop: 138.24,
    img_width_mobile: 70,
    img_height_mobile: 70,
  },
};

const useCases = [
  {
    title: i18n.t('common.use_cases.0'),
    icon: primaryCareIcon,
  },
  {
    title: i18n.t('common.use_cases.1'),
    icon: specialistCareIcon,
  },
  {
    title: i18n.t('common.use_cases.2'),
    icon: psychiatryIcon,
  },
  {
    title: i18n.t('common.use_cases.3'),
    icon: nursingIcon,
  },
  {
    title: i18n.t('common.use_cases.4'),
    icon: physiotherapyIcon,
  },
  {
    title: i18n.t('common.use_cases.5'),
    icon: chiropracticIcon,
  },
  {
    title: i18n.t('common.use_cases.6'),
    icon: veterinaryIcon,
  },
];

const Hero: FC<HeroInterface> = ({ windowWidth }) => {
  const duplicatedItems = [...useCases, ...useCases];
  const [ref, { width }] = useMeasure();
  const controls = useAnimation();
  const { t } = useTranslation();

  useEffect(() => {
    if (width) {
      const totalWidth = width / 2 + 23;
      const animationDuration = 25;

      void controls.start({
        x: [0, -totalWidth],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: 'loop',
            duration: animationDuration,
            ease: 'linear',
          },
        },
      });
    }
  }, [width, controls]);

  return (
    <div className={styles.heroContainer}>
      <div className={styles.heroMainSection}>
        <div className={styles.heroMainSectionTextArea}>
          <h3 className={styles.heroMainSectionSmallTitle}>
            {t('pages.home.subtitle')}
          </h3>
          <h1 className={styles.heroMainSectionLargeTitle}>
            {t('pages.home.title')}
          </h1>
        </div>
        <a
          href={start_trial_link()}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
          onClick={trackStartTrialClick}
        >
          <div className={styles.heroMainSectionButtonContainer}>
            <div className={styles.heroMainSectionButton}>
              <p>{t('buttons.start_trial')}</p>
            </div>
          </div>
        </a>
        <div className={styles.heroMainSectionTextArea}>
          <h3 className={styles.heroMainSectionTrialText}>
            {t('pages.home.text')}
          </h3>
        </div>
      </div>
      <div className={styles.heroCardsSection}>
        <div className={styles.heroCardsSectionCardsContainer}>
          {Object.values(CardsSectionContent).map((section, index) => (
            <div key={index} className={styles.heroCardsSectionCard}>
              <div className={styles.heroCardsSectionCardInnerContainer}>
                <div className={styles.heroCardsSectionCardImageContainer}>
                  <img
                    className={styles.heroCardsSectionCardImage}
                    src={section.img}
                    alt={`${section.title}`}
                  />
                </div>
                <p className={styles.heroCardsSectionCardTitle}>
                  {section.title}
                </p>
                <p className={styles.heroCardsSectionCardText}>
                  {section.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {windowWidth > 900 && (
        <div className={styles.heroUseCasesSection}>
          <motion.div
            ref={ref}
            className={styles.heroUseCasesContainer}
            animate={controls}
          >
            {duplicatedItems.map((useCase, index) => (
              <div key={index} className={styles.heroUseCase}>
                <img
                  src={useCase.icon}
                  alt={useCase.title}
                  style={{
                    filter:
                      'invert(30%) sepia(16%) saturate(342%) hue-rotate(349deg) brightness(93%) contrast(84%)',
                  }}
                />
                <p>{useCase.title}</p>
              </div>
            ))}
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default Hero;
