import React, { FC, useRef, useEffect } from 'react';
import styles from './GetStarted.module.css';
import { useTranslation } from 'react-i18next';
import { trackStartTrialClick } from '@app/util';
import mic from '@app/images/getStarted/mic.png';
import iphone from '@app/images/getStarted/iphone.png';
import laptop from '@app/images/getStarted/laptop.png';
import { start_trial_link } from '@app/util/language';

const ParallaxSection: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (section) {
        const scrollPosition = window.scrollY;
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const viewportHeight = window.innerHeight;

        if (
          scrollPosition + viewportHeight > sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          const relativePosition =
            (scrollPosition + viewportHeight - sectionTop) /
            (sectionHeight + viewportHeight);

          const laptop = section.querySelector(`.${styles.laptopParallax}`);
          const phone = section.querySelector(`.${styles.phoneParallax}`);
          const microphone = section.querySelector(`.${styles.micParallax}`);

          if (
            laptop instanceof HTMLElement &&
            phone instanceof HTMLElement &&
            microphone instanceof HTMLElement
          ) {
            laptop.style.transform = `translate(${relativePosition * 0}px, ${
              relativePosition * 20
            }px)`;
            phone.style.transform = `translate(${relativePosition * 0}px, ${
              relativePosition * 80
            }px)`;
            microphone.style.transform = `translate(${
              relativePosition * 0
            }px, ${relativePosition * -40}px)`;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={sectionRef} className={styles.parallaxSection}>
      <img src={laptop} alt="Laptop" className={styles.laptopParallax} />
      <img src={iphone} alt="Phone" className={styles.phoneParallax} />
      <img src={mic} alt="Microphone" className={styles.micParallax} />
    </div>
  );
};

const GetStarted: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.getStartedContainer}>
      <div className={styles.getStartedInnerContainer}>
        <div className={styles.getStartedContent}>
          <br />
          <h5>{t('pages.home.get_started.sub_header')}</h5>
          <h2>
            {t('pages.home.get_started.title.0')} <br />
            {t('pages.home.get_started.title.1')}
          </h2>
          <p>
            {t('pages.home.get_started.text.0')}
            {t('pages.home.get_started.text.1')}
          </p>
        </div>
        <ParallaxSection />
      </div>
      <div className={styles.getStartedButtons}>
        <a
          href={start_trial_link()}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
          onClick={trackStartTrialClick}
        >
          <div className={styles.getStartedContactInfoButton}>
            <p>{t('pages.home.get_started.action_button')}</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default GetStarted;
